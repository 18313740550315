<template>
  <b-card v-show="logsData">
    <b-overlay
      :show="!logsData"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1 mb-1">
        <!-- mobile -->
        <div class="mobile-version">
          <div class="custom-search mb-1">
            <div class="ir-top-box-left mb-1">
              <label style="font-weight: bold">Select table log</label>
              <b-form-select
                label="Status"
                :options="logsTablesOptions"
                @change="handleTableChange"
              />
            </div>
            <div
              v-if="selectedTable"
              class="ir-top-box-left mb-1"
            >
              <label class="mr-1">General search</label>
              <b-form-input
                id="GenericSearch"
                v-model="filter.search"
              />
            </div>
            <div
              v-if="selectedTable"
              class="ir-top-box-right mt-2 w-100"
            >
              <b-button
                v-b-modal.search-filter-modal
                variant="outline-primary"
                class="ir-searchFilterbtn"
              >
                Search Filters
              </b-button>
            </div>
          </div>
        </div>

        <!-- desktop -->
        <div class="float-left ir-btn-wrapper upper-select d-flex desktop-version">
          <b-form-group
            label="Select table log"
            label-for="table-type"
            style="width: 16rem; font-weight: bold;"
          >
            <b-form-select
              id="table-type"
              v-model="selectedTable"
              :options="logsTablesOptions"
              style="width: 16rem"
              @change="handleTableChange"
            />
          </b-form-group>
          <b-form-group
            v-if="hasStatusFilter && selectedTable"
            label="Select status"
            label-for="table-type"
            style="width: 10rem; margin-left: 1rem;"
          >
            <b-form-select
              id="table-type"
              v-model="filter.status"
              :options="[{ value: 'failed', text: 'Failed' }, { value: 'success', text: 'Success' }]"
              style="width: 10rem"
            />
          </b-form-group>
          <b-form-group
            v-if="selectedTable"
            label="Date Range"
            label-for="dateRange-type"
            style="width: 16rem !important; margin-left: 1rem;"
          >
            <date-range-picker
              id="dateRange-type"
              v-model="filter.dateRange"
              style="width: 16rem"
              value="Date Range"
            >
              <template style="min-width: 350px;">
                {{ filter.dateRange.startDate | date }} - {{ filter.dateRange.endDate | date }}
              </template>
              <!-- footer slot-->
              <div
                slot="footer"
                slot-scope="data"
                class="slot"
              >
                <div class="custom-search d-flex justify-content-end">
                  <span>Date Range:</span> <span>{{ data.rangeText }}</span>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="data.clickApply"
                  >
                    Ok
                  </b-button>
                </div>
              </div>
            </date-range-picker>
          </b-form-group>
        </div>
        <div
          v-if="selectedTable"
          class="float-left ir-btn-wrapper upper-select d-flex desktop-version"
        >
          <div class="custom-search mb-1">
            <div class="d-flex">
              <b-button
                variant="primary"
                class="mr-1"
                @click="handleApply"
              >
                Apply Filters
              </b-button>
              <b-button
                variant="outline-primary"
                @click="handleReset"
              >
                Reset Filters
              </b-button>
            </div>
          </div>
        </div>

        <!-- search input -->
        <div
          v-if="selectedTable"
          class="custom-search d-flex justify-content-end mt-2 desktop-version"
        >
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="filter.search"
                placeholder="General search"
                type="text"
                autocomplete="off"
                class="d-inline-block upper-select3"
                @keyup="handleSearch"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="logsFiltered"
          ref="table"
          :key="columns.length"
          :columns="columns"
          :rows="logsFiltered"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
          :search-options="{
            enabled: false,
          }"
          :sort-options="sortOptions"
          :class="['mt-4', 'table-mobile']"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
        >
          <template slot="loadingContent">
            <div
              class="my-1 d-flex spinner-border text-primary justify-content-center"
              style="width: 2.5rem; height: 2.5rem; margin: auto;"
              role="status"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              {{ props.row.id }}
            </span>

            <span v-else-if="props.column.field === 'created_at' || props.column.field === 'failed_at'">
              {{ formatDate(props.formattedRow[props.column.field]) }}
            </span>

            <span v-else-if="props.column.field === 'exception'">
              {{ first500Chars(props.row.exception) }}<br>
              <b-badge
                class="cursor-pointer"
                variant="light-primary"
                @click="openError(props.row.exception)"
              >
                <feather-icon icon="EyeIcon" />
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'data'">
              {{ first500Chars(props.row.data) }}<br>
              <b-badge
                class="cursor-pointer"
                variant="light-primary"
                @click="openError(props.row.data)"
              >
                <feather-icon icon="EyeIcon" />
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'extra_data'">
              <span v-if="props.row.extra_data">
                {{ first500Chars(props.row.extra_data) }}
                <br>
                <b-badge
                  class="cursor-pointer"
                  variant="light-primary"
                  @click="openError(props.row.extra_data)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-badge>
              </span> <span v-else>-</span>
            </span>

            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status).variant">
                {{ statusVariant(props.row.status).text }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'amount'">
              {{ Number(props.row.amount).toFixed(2) }}
            </span>

            <span v-else-if="props.column.field === 'error'">
              {{ props.row.error ? first500Chars(props.formattedRow[props.column.field]) : 'null' }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>

          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['100', '250', '500', '1000']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>

    <!-- Show Error Log -->
    <b-modal
      id="error-log-modal"
      ok-title="Close"
      ok-only
      centered
      size="lg"
      title="Error Logs"
    >
      <b-row>
        <b-col>{{ errorLog }}</b-col>
      </b-row>
    </b-modal>

    <!-- Search Filter -->
    <b-modal
      id="search-filter-modal"
      ok-title="Apply"
      cancel-title="Reset"
      centered
      title="Search Filter"
      @ok="handleApply"
      @cancel="handleReset"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group
              label="Date:"
              label-for="dateRange-type"
            >
              <date-range-picker
                v-model="filter.dateRange"
                style="width: 100%;"
              >
                <template style="min-width: 350px;">
                  {{ filter.dateRange.startDate | date }} - {{ filter.dateRange.endDate | date }}
                </template>
                <!-- footer slot-->
                <div
                  slot="footer"
                  slot-scope="data"
                  class="slot"
                >
                  <div class="custom-search d-flex justify-content-end">
                    <span>Date Range:</span> <span>{{ data.rangeText }}</span>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="data.clickApply"
                    >
                      Ok
                    </b-button>
                  </div>
                </div>
              </date-range-picker>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-form-group
              label="Status:"
              label-for="status-type"
            >
              <b-form-select
                id="status-type"
                v-model="filter.status"
                :options="[{ value: 'failed', text: 'Failed' }, { value: 'success', text: 'Success' }]"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BPagination, BFormSelect, BCard, BOverlay, BButton, BBadge, BModal,
  BForm, BFormGroup, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'

import 'vue2-datepicker/index.css'
import DateRangePicker from 'vue2-daterange-picker'
import axios from '@/libs/axios'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BOverlay,
    BButton,
    BCard,
    BBadge,
    BModal,
    BForm,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    DateRangePicker,
    BFormInput,
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat('en-US').format(date)
    },
  },
  data() {
    return {
      value: 0,
      max: 100,
      pageLength: 100,
      columns: [],
      rows: [],
      sortOptions: {
        enabled: true, // Enable sorting
        initialSortBy: [], // The initial sorting criteria (empty array for no initial sort)
      },
      filter: {
        search: null,
        status: null,
        email: null,
        language: null,
        dateRange: {
          startDate: null,
          endDate: null,
        },
      },
      logsData: [],
      isLoading: false,
      logsTablesOptions: [
        { value: 'AML', text: 'Auto mail logs' },
        { value: 'CE', text: 'Cron errors' },
        { value: 'CL', text: 'Cron logs' },
        { value: 'FJ', text: 'Failed jobs' },
        { value: 'PL', text: 'Payment logs' },
        { value: 'APL', text: 'Auto payment logs' },
      ],
      selectedTable: null,
      totalRecords: 0,
      filterParams: {},
      logsFiltered: [],
      tablesRows: {
        AML: ['created_at', 'status', 'emailed_to', 'purpose', 'error', 'credit_note'],
        CE: ['created_at', 'job', 'data', 'error'],
        CL: ['created_at', 'cron_name', 'extra_data'],
        FJ: ['failed_at', 'payload', 'exception', 'queue', 'connection'],
        PL: ['created_at', 'status', 'invoice_id', 'transaction_id', 'error', 'paid_width'],
        APL: ['created_at', 'status', 'credit_note', 'message', 'amount', 'transaction_id', 'payment_method'],
      },
      errorLog: '',
    }
  },
  computed: {
    hasStatusFilter() {
      if (this.selectedTable === 'AML' || this.selectedTable === 'PL' || this.selectedTable === 'APL') return true
      return false
    },
  },
  created() {
    this.fetchLogs()
  },
  methods: {
    handleTableChange(table) {
      this.filterParams = {}
      this.logsFiltered = []
      this.selectedTable = table
      this.sortOptions.initialSortBy = []
      this.columns = this.tablesRows[table].map(item => {
        if (item === 'payload') {
          return {
            label: item,
            field: item,
            hidden: true,
          }
        }
        if (item === 'credit_note') {
          return {
            label: item,
            field: item,
            sortFn: this.sortFnCreditNote,
          }
        }
        if (item === 'invoice_id') {
          return {
            label: item,
            field: item,
            sortFn: this.sortFnInvoiceId,
          }
        }

        return {
          label: item,
          field: item,
        }
      })
      if (this.columns.length) this.fetchLogs()
    },

    handleSearch() {
      this.filter.search = this.filter.search.toLowerCase()
      this.logsFiltered = this.logsData.filter(item => item.status?.toLowerCase().includes(this.filter.search)
        || item.emailed_to?.toLowerCase().includes(this.filter.search)
        || item.error?.toLowerCase().includes(this.filter.search)
        || item.job?.toLowerCase().includes(this.filter.search)
        || item.data?.toLowerCase().includes(this.filter.search)
        || item.extra_data?.toLowerCase().includes(this.filter.search)
        || item.payload?.toLowerCase().includes(this.filter.search)
        || item.transaction_id?.toLowerCase().includes(this.filter.search)
        || item.paid_width?.toLowerCase().includes(this.filter.search)
        || item.message?.toLowerCase().includes(this.filter.search)
        || item.payment_method?.toLowerCase().includes(this.filter.search)
        || item.amount?.toLowerCase().includes(this.filter.search)
        || item.cron_name?.toLowerCase().includes(this.filter.search)
        || parseInt(item.invoice_id) == this.filter.search
        || parseInt(item.credit_note) == this.filter.search
      )
      if (this.filter.search === '' || this.filter.search == null) this.fetchLogs()
      this.totalRecords = this.logsFiltered.length
    },

    statusVariant(status) {
      if (!status) {
        return {
          variant: 'light-dark',
          text: 'unknown',
        }
      }
      status = status.toLowerCase()
      const statusColor = {
        success: 'light-success',
        fail: 'light-danger',
        failed: 'light-danger',
        'n/a': 'light-dark',
        invalid: 'light-warning',
      }
      const statusText = {
        success: 'success',
        fail: 'fail',
        failed: 'failed',
        'n/a': 'unknown',
        invalid: 'invalid',
      }
      return {
        variant: statusColor[status],
        text: statusText[status],
      }
    },

    openError(error) {
      this.errorLog = error
      this.$bvModal.show('error-log-modal')
    },

    sortFnDate(x, y, col, rowX, rowY) {
      const value1 = Date.parse(this.filterFormatDate(rowX.created_at))
      const value2 = Date.parse(this.filterFormatDate(rowY.created_at))

      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0))
    },

    fetchLogs() {
      if (!this.selectedTable) {
        this.selectedTable = 'AML'
        this.columns = this.tablesRows[this.selectedTable].map(item => {
          if (item === 'credit_note') {
            return {
              label: item,
              field: item,
              sortFn: this.sortFnCreditNote,
            }
          }

          return {
            label: item,
            field: item,
          }
        })
      }

      axios.get(`/api/global-logs/${this.selectedTable}`).then(res => {
        if (res.data?.result) {
          this.logsData = res.data.result
          this.logsFiltered = res.data.result
          this.totalRecords = res.data.result.length
        }
      }).catch(error => {
        console.log(error)
      })
    },

    handleApply() {
      this.filterParams = {}

      if (this.filter.dateRange.startDate !== null) {
        this.filterParams.dateRange = {
          startDate: moment(this.filter.dateRange.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.filter.dateRange.endDate).format('YYYY-MM-DD'),
        }
      }
      if (this.filter.status !== null) {
        this.filterParams.status = this.filter.status
      }

      this.fillterLogs()
    },

    fillterLogs() {
      if (Object.keys(this.filterParams).length === 0) {
        this.fetchLogs()
        return
      }

      axios.post('api/global-logs/filter', {
        filters: this.filterParams,
        table: this.selectedTable,
      }).then(res => {
        this.logsData = res.data.result
        this.logsFiltered = res.data.result
        this.totalRecords = res.data.result.length
      }).catch(error => {
        console.log(error)
      })
    },

    first500Chars(text) {
      return text?.length > 500 ? `${text.substring(0, 500)}...` : text
    },

    formatDate(date) {
      return date != null ? moment(new Date(date)).format('DD MMM YYYY | HH:mm:ss') : '-'
    },

    sortFnCreditNote(x, y, col, rowX, rowY) {
      const value1 = parseInt(rowX.credit_note) || 0
      const value2 = parseInt(rowY.credit_note) || 0

      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0))
    },

    sortFnInvoiceId(x, y, col, rowX, rowY) {
      const value1 = parseInt(rowX.invoice_id) || 0
      const value2 = parseInt(rowY.invoice_id) || 0

      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0))
    },

    handleReset() {
      this.filterParams = {}
      this.filter = {
        search: null,
        status: null,
        dateRange: {
          startDate: null,
          endDate: null,
        },
      }
      this.fetchLogs()
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.calendars {
  color: black;
}

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr {
    background: #f3f2f7;
  }

  th,
  td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 60rem !important;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}

.slot {
  padding: 0.5rem;
  justify-content: space-between;
}

.slot span {
  margin-right: 5px;
  margin-top: 5px;
  font-size: 11px;
  font-weight: bold;
}

.modal .modal-header .close {
  margin: 2px
}

.filter-th {
  display: none;
}

.vgt-global-search {
  display: none;
}

.vgt-selection-info-row {
  margin-top: 5px !important
}

@media only screen and (min-width: 768px) {

  /* For desktop: */
  .desktop-version {
    display: flex !important;
  }

  .mobile-version {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  .desktop-version {
    display: none !important;
  }

  .mobile-version {
    display: block !important;
  }

  .custom-class {
    height: 25px !important;
    margin-top: 25rem !important;
  }
}
</style>
